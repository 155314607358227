import { Component } from '@angular/core';
import { environment } from "../../environments/environment";
import { FormService } from "../services/form.service";
import { UtilService } from "../services/util.service";
import { ToastrService } from 'ngx-toastr';

import * as moment from 'moment';

@Component({
  selector: 'form-micheluniv',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent {
  env = environment.globals

  diploma: boolean = false
  undergraduate: boolean = false
  graduate: boolean = false
  doctoralProgramme: boolean = false
  fullName: string = "" // duda
  nib: string = "" // duda
  address: string = ""
  city: string = ""
  state: string = ""
  zipCode: string = ""
  country: string = ""
  cellNumber: string = ""
  phoneNumber: string = ""
  facebookAccount: string = ""
  email: string = ""
  dateBirth: string = ""
  citizenship: string = ""
  permanentResident: boolean = false
  employment: string = ""
  posision: string = ""
  workPhoneNumber: string = ""
  employmentAddress: string = ""
  employmentCity: string = ""
  employmentState: string = ""
  employmentZipCode: string = ""
  employmentCountry: string = ""
  employmentYears: string = ""
  college: string = ""
  programMajor: string = ""
  priorLearningExperience: string = ""
  course: string = ""
  amountPaidToDate: string = ""
  datePaid: string = ""
  agree: boolean = false
  agreeDate: string = moment().format('DD/MM/YYYY')

  constructor(
    private foServ: FormService,
    private toastr: ToastrService,
    private util: UtilService
  ) { }

  submitForm() {
    this.foServ.getById(this.email).subscribe(res => {
      if (!res) {
        this.foServ.addItemById(this.email, {
          diploma: this.diploma,
          undergraduate: this.undergraduate,
          graduate: this.graduate,
          doctoralProgramme: this.doctoralProgramme,
          fullName: this.fullName,
          nib: this.nib,
          address: this.address,
          city: this.city,
          state: this.state,
          zipCode: this.zipCode,
          country: this.country,
          cellNumber: this.cellNumber,
          phoneNumber: this.phoneNumber,
          facebookAccount: this.facebookAccount,
          email: this.email,
          dateBirth: this.dateBirth,
          citizenship: this.citizenship,
          permanentResident: this.permanentResident,
          employment: this.employment,
          posision: this.posision,
          workPhoneNumber: this.workPhoneNumber,
          employmentAddress: this.employmentAddress,
          employmentCity: this.employmentCity,
          employmentState: this.employmentState,
          employmentZipCode: this.employmentZipCode,
          employmentCountry: this.employmentCountry,
          employmentYears: this.employmentYears,
          college: this.college,
          programMajor: this.programMajor,
          priorLearningExperience: this.priorLearningExperience,
          course: this.course,
          amountPaidToDate: this.amountPaidToDate,
          datePaid: this.datePaid,
          agree: this.agree,
          agreeDate: this.agreeDate,
        }).then(result => {
          this.toastr.success('Form added. We will be in contact with you as soon as possible.', 'Message', { closeButton: true, progressBar: true });
          this.clean()
        })
      } else {
        this.toastr.success('We already have this form. We will be in contact with you as soon as possible.', 'Message', { closeButton: true, progressBar: true });
      }
    })
  }

  verifications() {
    return this.util.isValidEmail(this.email) &&
      (this.diploma || this.undergraduate || this.graduate || this.doctoralProgramme) &&
      this.util.isNotEmptyString(this.fullName) &&
      this.agree
  }

  clean() {
    this.diploma = false
    this.undergraduate = false
    this.graduate = false
    this.doctoralProgramme = false
    this.fullName = "" // duda
    this.nib = "" // duda
    this.address = ""
    this.city = ""
    this.state = ""
    this.zipCode = ""
    this.cellNumber = ""
    this.country = ""
    this.phoneNumber = ""
    this.facebookAccount = ""
    this.email = ""
    this.dateBirth = ""
    this.citizenship = ""
    this.permanentResident = false
    this.employment = ""
    this.posision = ""
    this.workPhoneNumber = ""
    this.employmentAddress = ""
    this.employmentCity = ""
    this.employmentState = ""
    this.employmentZipCode = ""
    this.employmentCountry = ""
    this.employmentYears = ""
    this.college = ""
    this.programMajor = ""
    this.priorLearningExperience = ""
    this.course = ""
    this.amountPaidToDate = ""
    this.datePaid = ""
    this.agree = false
    this.agreeDate = ""
  }
}
