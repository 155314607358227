import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FireModel } from './aModel.service';


export interface Global {
  version: number ; 
}

@Injectable({
  providedIn: 'root'
})

export class GlobalService extends FireModel<Global> {
  collection: string = 'global'
  constructor(
    public db: AngularFirestore
  ) {
    super('global', db)
  }
}