import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';  // Firebase modules for Database, Data list and Single object
import { Model } from './aModel.service';

@Injectable({
  providedIn: 'root'
})
export class AwardsService extends Model{

  collection: string = 'awards'
  studentsRef: AngularFireList<any>;    // Reference to Student data list, its an Observable
  studentRef: AngularFireObject<any>;   // Reference to Student object, its an Observable too

  constructor(
    public db: AngularFireDatabase
  ) { 
    super('awards',db)
  }

  toString(){
    return ""
  }

  toJson(){
    return {}
  }
}
