import { Component, OnInit } from '@angular/core';
import { UsersService } from "../services/users.service";
import { OrdersService } from "../services/orders.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  user: any = {}
  orders: number = 0
  transactions: number = 0
  balance: number = 0
  purchases: number = 0

  constructor(
    private usrServ: UsersService,
    private ordServ: OrdersService,
  ) { }

  ngOnInit() {
    this.user = this.usrServ.ifInvalidUserToLogin()
    this.usrServ.checkSystemVersion()
    /* if (this.usrServ.isUserManage()) {
      this.usrServ.goTo("/adminDashboard")
    } else {
      this.ordServ.getBy("email", this.user.email).subscribe(orders=>{
        this.orders = orders.length
      })
    }*/
  }
}
