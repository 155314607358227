import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import * as firebaseui from 'firebaseui';

import { UsersService } from "../services/users.service";
import { PeopleService } from "../services/people.service";
import { environment } from "../../environments/environment";
import * as $ from "jquery";

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  env = environment.globals;
  user: any = {};
  ui: firebaseui.auth.AuthUI

  constructor(
    private usrServ: UsersService,
    private pServ: PeopleService,
    private router: Router,
    public afAuth: AngularFireAuth,
    private toastr: ToastrService
  ) {
    this.ui = new firebaseui.auth.AuthUI(this.afAuth.auth)
  }

  ngOnInit() {
    
    //background-color: #fedb00;    
    this.user = this.usrServ.ifValidUserToMain()

    /* recaptchaCallback = () => {
      this.captcha = true
      console.log("done", this.captcha)
      $("#email").focus()
    } */
    const uiConfig = {
      //signInSuccessUrl: 'dashboard',
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        auth.GoogleAuthProvider.PROVIDER_ID,
        //auth.FacebookAuthProvider.PROVIDER_ID,
        //auth.TwitterAuthProvider.PROVIDER_ID,
        //auth.GithubAuthProvider.PROVIDER_ID,
        auth.EmailAuthProvider.PROVIDER_ID,
        //auth.PhoneAuthProvider.PROVIDER_ID,
      ],
      // tosUrl and privacyPolicyUrl accept either url string or a callback
      // function.
      // Terms of service url/callback.
      //tosUrl: '<your-tos-url>',
      // Privacy policy url/callback.
      /* privacyPolicyUrl: function() {
        window.location.assign('<your-privacy-policy-url>');
      } */
      callbacks: {
        signInSuccessWithAuthResult: this.onLoginSuccessful.bind(this)
      }
    }



    this.ui.start("#firebaseui", uiConfig)
  }

  onLoginSuccessful(authResult, redirectUrl) {
    //console.log(authResult)
    //console.log(redirectUrl)
    if (authResult && authResult.user) {
      this.user = {
        displayName: authResult.user.displayName,
        email: authResult.user.email,
        emailVerified: authResult.user.emailVerified,
        isAnonymous: authResult.user.isAnonymous,
        creationTime: authResult.user.metadata.creationTime,
        lastSignInTime: authResult.user.metadata.lastSignInTime,
        phoneNumber: authResult.user.phoneNumber,
        photoURL: authResult.user.photoURL
      }      
      //this.pServ.getBy("email",this.user.email).subscribe(res=>{
      this.pServ.getById(this.user.email).subscribe(res=>{
        console.log("res",res);
        if(!res){
          this.pServ.addItemById(this.user.email,this.user)
        }else{
         this.user = res
        }
        this.usrServ.setSession('user', JSON.stringify(this.user))
        this.usrServ.goTo("dashboard")
      })
    }

  }

}
