import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';


import { HomeComponent } from './home/home.component';
import { AccreditationComponent } from './accreditation/accreditation.component';
import { CostComponent } from './cost/cost.component';
import { MissionComponent } from './mission/mission.component';
import { LoginComponent } from './login/login.component';
import { CourseComponent } from './course/course.component';
import { CoursesComponent } from './courses/courses.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { DashboardNavbarComponent } from './dashboard-navbar/dashboard-navbar.component';
import { DashboardSidebarComponent } from './dashboard-sidebar/dashboard-sidebar.component';
import { DashboardFooterComponent } from './dashboard-footer/dashboard-footer.component';
import { ManageCoursesComponent } from './manage-courses/manage-courses.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { PrincipalCourseComponent } from './principal-course/principal-course.component';
import { FormComponent } from './form/form.component';
import { ProfessorsComponent } from './professors/professors.component';
import { GraduationComponent } from './graduation/graduation.component';

import { InputComponent } from './components/osa-input/osa-input.component';

import { UsersService } from "./services/users.service";
import { PeopleService } from "./services/people.service";
import { AdminService } from "./services/admin.service";
import { AwardsService } from "./services/awards.service";
import { CoursesService } from "./services/courses.service";
import { GlobalService } from "./services/models.service";
import { StoreService } from "./services/storage.service";
import { FormService } from "./services/form.service";
import { UtilService } from "./services/util.service";

//firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
//import * as firebaseui from 'firebaseui';
import { environment } from '../environments/environment';

//notif
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { GradingComponent } from './grading/grading.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    CourseComponent,
    AccreditationComponent,
    CostComponent,
    MissionComponent,
    CoursesComponent,
    DashboardComponent,
    DashboardNavbarComponent,
    DashboardSidebarComponent,
    DashboardFooterComponent,
    AdminDashboardComponent,
    ManageCoursesComponent,
    ManageUsersComponent,
    PrincipalCourseComponent,
    FormComponent,
    ProfessorsComponent,
    InputComponent,
    GraduationComponent,
    GradingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
  ],
  providers: [
    UsersService,
    AdminService,
    AwardsService,
    PeopleService,
    CoursesService,
    AngularFirestore,
    GlobalService,
    StoreService,
    AngularFireStorage,
    FormService,
    UtilService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
