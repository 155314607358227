import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'osa-input',
  templateUrl: './osa-input.component.html',
  styleUrls: ['./osa-input.component.css']
})
export class InputComponent implements OnInit {
  @Input('name') name: string;
  @Input() value: string;
  @Input('help') help: string;
  @Input('placeholder') placeholder: string;
  @Input('type') type: string="text";
  @Input('mandatory') mandatory: boolean = false;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  onBlur: boolean = false;

  constructor(
  ) {
  }

  ngOnInit() {
  }

  keyPress(event: any) {
    this.valueChange.emit(this.value);
  }

}
