import { Component} from '@angular/core';
import { environment } from "../../environments/environment";

@Component({
  selector: 'principal-course',
  templateUrl: './principal-course.component.html',
  styleUrls: ['./principal-course.component.css']
})
export class PrincipalCourseComponent {  
  env = environment.globals
  constructor(
  ) { }
}
