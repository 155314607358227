import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UtilService {


    constructor(
    ) {

    }

    isValidEmail(email) {
        const reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return reg.test(email);
    }

    isNotEmptyString(string = "") {
        return string != null && string.replace(" ","") != ""
    }

}
