import { Component, OnInit } from '@angular/core';
import { UsersService } from "../services/users.service";
import { AdminService } from "../services/admin.service";
import { PeopleService } from "../services/people.service";
import { CoursesService } from "../services/courses.service";
import { environment } from "../../environments/environment";


@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {

  user: any = {}
  totalUsers: number = 0
  totalUsersAdmin: number = 0
  totalUsersStudents: number = 0
  totalCourses: number = 0
  classifications: Array<string> = environment.globals.classifications
  coursesClasification = []
  constructor(
    private usrServ: UsersService,
    private admServ: AdminService,
    private pServ: PeopleService,
    private coServ: CoursesService,
  ) { }

  ngOnInit() {
    this.user = this.usrServ.ifInvalidUserToLogin()
    this.usrServ.checkSystemVersion()
    if (!this.usrServ.isUserManage()) {
      this.usrServ.goTo("/dashboard")
    }

    this.pServ.getAll().subscribe(users => {
      this.totalUsers = users.length

      this.totalUsersAdmin = users.filter(e => e.isAdmin).length;
      this.totalUsersStudents = users.filter(e => !e.isAdmin).length;
    })

    this.coServ.getAll().subscribe(courses => {
      this.totalCourses = courses.length
      this.classifications.forEach(e => {
        this.coursesClasification.push(courses.filter(e1 => e1.clase === e))
      })
    })

  }

  getPercentageString(classArray: Array<string>) {
    return this.getPercentage(classArray) + "%"
  }

  getPercentage(classArray: Array<string>) {
    if (classArray && classArray.length > 0)
      return (classArray.length / this.totalCourses * 100).toFixed(1)
    return 0
  }

  getUserPercentage(number: number) {
    return (number / this.totalUsers * 100).toFixed(1)
  }

}
