import { Component} from '@angular/core';
import { environment } from "../../environments/environment";

@Component({
  selector: 'professors',
  templateUrl: './professors.component.html',
  styleUrls: ['./professors.component.css']
})
export class ProfessorsComponent {  
  env = environment.globals
  constructor(
  ) { }
}
