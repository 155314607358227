import { Component} from '@angular/core';
import { environment } from "../../environments/environment";

@Component({
  selector: 'mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.css']
})
export class MissionComponent {  
  env = environment.globals
  constructor(
  ) { }
}
