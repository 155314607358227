import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})

export class StoreService {
  constructor(
    public storage: AngularFireStorage
  ) {
  }

  getDownloadURL(resource: string) {
    const ref = this.storage.ref(resource);
    return ref.getDownloadURL();
  }

}
