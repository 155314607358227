import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';

import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';

import { GlobalService, Global } from './models.service'
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const httpCheckoutOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'pk_78cc7952-478a-4a11-97d0-b68d616b8815' })
};
const httpOptionsFiles = {
  headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' })
};

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private apiURL = '/api/v1';  // URL to web api
  private appPrefix = 'michaeluniversity_app'
  socket: any = {};

  constructor(
    private http: HttpClient,
    private router: Router,
    private meta: Meta,
    private gloService: GlobalService,
    public firebase: AngularFireAuth
  ) {

  }

  createUserWithEmailAndPassword(email, password) {
    return auth().createUserWithEmailAndPassword(email, password)
  }

  login(email, password) {
    return auth().signInWithEmailAndPassword(email, password)
  }

  onAuthStateChanged(done) {
    auth().onAuthStateChanged(function (user) {
      done(user)
    })
  }

  logout() {
    this.removeSession('user');
    this.router.navigate(['/login']);
  }

  status(): Observable<any> {
    return this.http
      .post(`${this.apiURL}/status`, {}, httpOptions)
      .pipe(
        tap(user => this.setSession('user', JSON.stringify(user)))
      );
  }

  getSession(elem: string): string {
    return window.localStorage.getItem(this.appPrefix + elem);
  }

  setSession(elem: string, val: any) {
    window.localStorage.setItem(this.appPrefix + elem, val);
  }

  removeSession(elem: string) {
    this.http.post(`${this.apiURL}/logout`, httpOptions).subscribe(response => {
      //console.log(response)
    })
    window.localStorage.removeItem(this.appPrefix + elem);
  }

  ifValidUserToMain() {
    let user = JSON.parse(this.getSession('user'));
    if (user) {
      this.router.navigate(['/dashboard']);
    }
    return user
  }

  ifInvalidUserToLogin() {
    let user = JSON.parse(this.getSession('user'));
    if (!user) {
      this.router.navigate(['/login']);
    }
    return user
  }

  checkCode(code): Observable<any> {
    return this.http.post(`${this.apiURL}/checkCode`, code, httpOptions)
      .pipe(
        tap(user => {
          if (user) {
            this.setSession('user', JSON.stringify(user))
          }
        })
      );;
  }

  checkoutTokenCard(data): Observable<any> {
    return this.http.post(`https://api2.checkout.com/v2/tokens/card`, data, httpCheckoutOptions)
  }
  checkSystemVersion() {
    this.gloService.getById("webconfig").subscribe(res => {
      if (!this.getSession("system-version") || res.version != parseInt(this.getSession("system-version"))) {
        this.setSession("system-version", res.version)
        console.log("version need to be updated!!!")
        setTimeout(() => {
          location.reload(true)
        }, 1000);
      } else {
        console.log("version updated:"+res.version)
      }
    })
  }

  goTo(page) {
    this.router.navigate([page]);
  }

  goToWithParam(page, parameter) {
    console.log(page, parameter)
    this.router.navigate([page, parameter]);
  }

  isUserManage() {
    let user = JSON.parse(this.getSession('user'));
    //console.log(user)
    if (user.isAdmin) {
      return true
    }
    return false
  }

  isUserDriver() {
    let user = JSON.parse(this.getSession('user'));
    if (user.isDriver) {
      return true
    }
    return false
  }

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

}
