import { Component, OnInit } from '@angular/core';
import { UsersService } from "../services/users.service";
import { OrdersService } from "../services/orders.service";
import { CoursesService, Course } from "../services/courses.service";
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";

import { ToastrService } from 'ngx-toastr';
/* import * as $ from "jquery";*/

@Component({
  selector: 'manage-courses',
  templateUrl: './manage-courses.component.html',
  styleUrls: ['./manage-courses.component.css']
})
export class ManageCoursesComponent implements OnInit {

  env = environment.globals
  user: any = {}
  courses: Observable<Course[]>;
  coursesSize: number;
  courseTitle: string = ""
  clase: string = ""
  creditHours: number = 0
  admissionRequirements: string = ""
  applicationProcess: string = ""
  items: string = ""
  opportunities: string = ""
  overview: string = ""
  id: string = ""
  classification: string = ""
  featured: boolean = false
  editing: boolean = false

  constructor(
    private usrServ: UsersService,
    private coServ: CoursesService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.user = this.usrServ.ifInvalidUserToLogin()
    this.usrServ.checkSystemVersion()
    if (!this.usrServ.isUserManage()) {
      this.usrServ.goTo("/dashboard")
    }
    this.courses = this.coServ.getAll()

    this.courses.subscribe((courses) => {
      setTimeout(() => {
        $('#dataTable').DataTable();
      }, 100);
      this.coursesSize = courses.length
    })
    /* $(document).ready(function() {
      $('#dataTable').DataTable();
    }); */
  }


  courseVerifications() {
    return this.courseTitle === "";
  }

  prepareEditCourse(item: Course) {
    this.editing = true
    this.id = item["id"]
    this.courseTitle = item.title
    this.admissionRequirements = item.admissionRequirements.join(",")
    this.applicationProcess = item.applicationProcess.join(",")
    this.items = item.items.join(",")
    this.opportunities = item.opportunities.join(",")
    this.overview = item.overview
    this.clase = item.clase
    this.creditHours = item.creditHours
    this.featured = item.featured
  }

  createCourse() {
    console.log(this.id, this.courseTitle)

    this.coServ.addItem({
      title: this.courseTitle,
      image: "",
      description: "",
      pdf: "",
      video: "",
      admissionRequirements: this.admissionRequirements.split(","),
      applicationProcess: this.applicationProcess.split(","),
      items: this.items.split(","),
      opportunities: this.opportunities.split(","),
      overview: this.overview,
      clase: this.clase,
      creditHours: this.creditHours,
      featured: false,
      views: 0,
      reviews: 0
    }).then(result => {
      this.toastr.success('Course added', 'Message', { closeButton: true, progressBar: true });
      this.clean()
    })
  }

  editCourse() {
    console.log(this.id, this.courseTitle)
    this.editing = false
    this.coServ.update(this.id, {
      title: this.courseTitle,
      admissionRequirements: this.admissionRequirements.split(","),
      applicationProcess: this.applicationProcess.split(","),
      items: this.items.split(","),
      opportunities: this.opportunities.split(","),
      overview: this.overview,
      clase: this.clase,
      creditHours: this.creditHours,
      featured: this.featured
    }).then(result => {
      this.toastr.success('Course modified', 'Message', { closeButton: true, progressBar: true });
      this.clean()
    })
  }

  clean() {
    $("button.close").click()
    this.editing = false
    this.featured = false
    this.courseTitle = ""
    this.admissionRequirements = ""
    this.applicationProcess = ""
    this.items = ""
    this.opportunities = ""
    this.overview = ""
    this.clase = ""
    this.creditHours = 0
    //$('#AddCourse').modal('dispose') 
  }

}
