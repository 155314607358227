import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { HomeComponent } from './home/home.component';
import { AccreditationComponent } from './accreditation/accreditation.component';
import { CostComponent } from './cost/cost.component';
import { MissionComponent } from './mission/mission.component';
import { LoginComponent } from './login/login.component';
import { CourseComponent } from './course/course.component';
import { CoursesComponent } from './courses/courses.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { ManageCoursesComponent } from './manage-courses/manage-courses.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { PrincipalCourseComponent } from './principal-course/principal-course.component';
import { FormComponent } from './form/form.component';
import { ProfessorsComponent } from './professors/professors.component';
import { GraduationComponent } from './graduation/graduation.component';
import { GradingComponent } from './grading/grading.component';



const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'programs',
    component: CoursesComponent
  },
  {
    path: 'course/:id',
    component: CourseComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'adminDashboard',
    component: AdminDashboardComponent
  },
  {
    path: 'manageCourses',
    component: ManageCoursesComponent
  },
  {
    path: 'manageUsers',
    component: ManageUsersComponent
  },
  {
    path: 'accreditation',
    component: AccreditationComponent
  },
  {
    path: 'cost',
    component: CostComponent
  },
  {
    path: 'mission',
    component: MissionComponent
  },
  {
    path: 'principal-course',
    component: PrincipalCourseComponent
  },
  {
    path: 'form',
    component: FormComponent
  },
  {
    path: 'professors',
    component: ProfessorsComponent
  },
  {
    path: 'graduation',
    component: GraduationComponent
  },
  {
    path: 'grading',
    component: GradingComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
