import { Component, OnInit } from '@angular/core';
//import { TranslateService } from '@ngx-translate/core';

import { UsersService } from "../services/users.service";
import { CoursesService } from "../services/courses.service";
import { environment } from "../../environments/environment";
import { Observable } from 'rxjs';

@Component({
  selector: 'app-plans',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {
  env = environment.globals
  currentRate: number = 4
  readonly: boolean = true
  search: string = ""
  plans: Observable<any[]>
  classifications:Array<string> = environment.globals.classifications
  classificationIcons:Array<string> = ["fas fa-laptop","fas fa-briefcase","fas fa-heartbeat","fas fa-users","fas fa-cogs"]
  filterData=[]
  paidAds = []
  website = []
  publicRelations = []
  user: any = {}
  constructor(
    //private translate: TranslateService,
    private usrServ: UsersService,
    private coServ: CoursesService,
  ) {
    //translate.setDefaultLang('en');
  }

  ngOnInit() {
    this.plans = this.coServ.getAll()
    this.plans.subscribe(plans => {
      //console.log(plans)
      this.classifications.forEach(e=>{
        this.filterData.push(plans.filter(e1 => e1.clase === e))
      })      
    })
    
    this.user = JSON.parse(this.usrServ.getSession('user'));
  }

  details(id) {
    console.log(id)
    this.usrServ.goToWithParam("/course", id)
  }

  inSeach(search: string, title: string) {
    return title.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
  }

  scroll(id) {
    let el = document.getElementById(id);
    el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  getImage(title: string) {
    return ""
  }

  resume(text: string){
    return text.slice(0,100)
    .replace(new RegExp("<h2>","g"),"")
    .replace(new RegExp("</h2>","g"),"")
    .replace(new RegExp("<p>","g"),"")
    .replace(new RegExp("</p>","g"),"") + "..."
  }

}
