import { Component} from '@angular/core';
import { environment } from "../../environments/environment";

@Component({
  selector: 'accreditation',
  templateUrl: './accreditation.component.html',
  styleUrls: ['./accreditation.component.css']
})
export class AccreditationComponent {  
  env = environment.globals
  constructor(
  ) { }
}
