import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FireModel } from './aModel.service';


export interface People {
  creationTime: string;
  displayName: string;
  email: string;
  emailVerified: boolean;
  id: string;
  isAdmin: boolean;
  isAnonymous: boolean;
  lastSignInTime: string;
  photoURL: string;
  phoneNumber: string;
  //points: number ; 
}

export interface Access {
  isAdmin: boolean,
  isTeacher: boolean,
  isManager: boolean
}

@Injectable({
  providedIn: 'root'
})

export class PeopleService extends FireModel<People> {

  collection: string = 'people'

  constructor(
    public db: AngularFirestore
  ) {
    super('people', db)
  }

  toString() {
    return ""
  }

  toJson() {
    return {}
  }
}

export class AccessService extends FireModel<Access> {

  collection: string = 'access'

  constructor(
    public db: AngularFirestore
  ) {
    super('access', db)
  }

  toString() {
    return ""
  }

  toJson() {
    return {}
  }
}
