import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FireModel } from './aModel.service';


export interface Form {
  diploma: boolean 
  undergraduate: boolean 
  graduate: boolean 
  doctoralProgramme: boolean 
  fullName: string  // duda
  nib: string  // duda
  address: string 
  city: string 
  state: string 
  zipCode: string 
  country: string 
  cellNumber: string 
  phoneNumber: string 
  facebookAccount: string 
  email: string 
  dateBirth: string
  citizenship: string 
  permanentResident: boolean 
  employment: string 
  posision: string 
  workPhoneNumber: string 
  employmentAddress: string 
  employmentCity: string 
  employmentState: string 
  employmentZipCode: string 
  employmentCountry: string 
  employmentYears: string 
  college: string 
  programMajor: string 
  priorLearningExperience: string 
  course: string 
  amountPaidToDate: string 
  datePaid: string
  agree: boolean 
  agreeDate: string 
}

@Injectable({
  providedIn: 'root'
})

export class FormService extends FireModel<Form> {

  collection: string = 'form'

  constructor(
    public db: AngularFirestore
  ) {
    super('form', db)
  }

  toString() {
    return ""
  }

  toJson() {
    return {}
  }
}