import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FireModel } from './aModel.service';


export interface Course { 
  overview: string; 
  title: string; 
  clase: string; 
  image: string; 
  video: string; 
  description: string; 
  pdf: string; 
  creditHours: number; 
  reviews: number; 
  items: string[]; 
  opportunities: string[] ; 
  applicationProcess: string[] ; 
  admissionRequirements: string[] ; 
  featured:boolean;
  views: number; 
}

@Injectable({
  providedIn: 'root'
})

export class CoursesService extends FireModel<Course>{
  constructor(
    public db: AngularFirestore
  ) { 
    super('courses',db)
  }

  toString() {
    return ""
  }

  toJson() {
    return {}
  }
}
