import { Component, OnInit } from '@angular/core';
import { UsersService } from "../services/users.service";
import { ActivatedRoute } from '@angular/router';
import { CoursesService, Course } from "../services/courses.service";
import { environment } from "../../environments/environment";
import { Meta, DomSanitizer } from '@angular/platform-browser';
import { StoreService } from "../services/storage.service";
import $ from 'jquery'

@Component({
  selector: 'course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css']
})
export class CourseComponent implements OnInit {

  section: string = ""
  env = environment.globals
  email: string = ""
  currentRate: number = 4
  plan: Course = {
    title: "",
    description: "",
    overview: "",
    video: "",
    views: 0,
    pdf: "",
    reviews: 0,
    admissionRequirements: [""],
    applicationProcess: [""],
    creditHours: 0,
    clase: '',
    image: '',
    items: [],
    opportunities: [],
    featured: false
  }
  user: any = {}
  showMessage: string = ""
  safeVideo
  pdfURL
  constructor(
    private usrServ: UsersService,
    private coServ: CoursesService,
    private route: ActivatedRoute,
    private meta: Meta,
    private sanitizer: DomSanitizer,
    private storeServ: StoreService,
  ) {


  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.section = params['id'];
      //console.log(this.section);
      this.coServ.getById(this.section).subscribe(res => {
        this.plan = {
          title: res.title || "",
          description: res.description || "",
          overview: res.overview || "",
          video: res.video || "",
          views: res.views || 0,
          pdf: res.pdf || "",
          reviews: res.reviews || 0,
          admissionRequirements: res.admissionRequirements || [],
          applicationProcess: res.applicationProcess || [],
          creditHours: res.creditHours || 0,
          clase: res.clase || '',
          image: res.image || '',
          items: res.items || [],
          opportunities: res.opportunities || [],
          featured: res.featured || false
        };
        //console.log(this.plan)
        if (!this.plan["views"]) {
          this.plan["views"] = 0
        }
        if (this.plan["pdf"]) {
          this.storeServ.getDownloadURL(this.plan["pdf"]).subscribe(url => {
            this.pdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);;
          });
        }
        this.safeVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.plan["video"].indexOf("youtube") > -1 ? this.plan["video"].replace("watch?v=", "embed/") : this.plan["video"]);

      })
    });

    /* setTimeout(() => {
      if (typeof this.plan["views"] == 'number') {
        this.coServ.update(this.section, { views: this.plan["views"] + 1 })
      }
    }, 1000); */

    this.user = JSON.parse(this.usrServ.getSession('user'));

  }

}
