import { Component, OnInit } from '@angular/core';
import { UsersService } from "../services/users.service";
import { AdminService } from "../services/admin.service";
import { environment } from "../../environments/environment";


@Component({
  selector: 'dashboard-navbar',
  templateUrl: './dashboard-navbar.component.html',
  styleUrls: ['./dashboard-navbar.component.css']
})
export class DashboardNavbarComponent implements OnInit {

  env = environment.globals;
  messages: Array<any> = []
  notifications: Array<any> = []
  user: any = {}

  constructor(
    private usrServ: UsersService,
    private admServ: AdminService,
  ) { }

  ngOnInit() {
    this.user = this.usrServ.ifInvalidUserToLogin()
  }

  logout() {
    //event.preventDefault();
    this.usrServ.removeSession('user');
    //this.router.navigate(['/login']);
    location.href = '/'
  }

}
