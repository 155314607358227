import { Component, OnInit } from '@angular/core';
import { UsersService } from "../services/users.service";
import { PeopleService, People } from "../services/people.service";
import { Observable } from 'rxjs';
/* import * as $ from "jquery";*/

@Component({
  selector: 'manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css']
})
export class ManageUsersComponent implements OnInit {

  user: any = {}
  people: Observable<People[]>;
  title: string = ""
  clase: string = ""
  creditHours: number = 0
  admissionRequirements: string = ""
  applicationProcess: string = ""
  items: string = ""
  opportunities: string = ""
  overview: string = ""
  id: string = ""
  classification: string = ""
  featured: boolean = false
  editing: boolean = false

  constructor(
    private usrServ: UsersService,
    private peServ: PeopleService,
  ) { }

  ngOnInit() {
    this.user = this.usrServ.ifInvalidUserToLogin()
    this.usrServ.checkSystemVersion()
    if (!this.usrServ.isUserManage()) {
      this.usrServ.goTo("/dashboard")
    }
    this.people = this.peServ.getAll()

    this.people.subscribe(() => {
      setTimeout(() => {
        $('#dataTable').DataTable();
      }, 100);
    })
    /* $(document).ready(function() {
      $('#dataTable').DataTable();
    }); */
  }

}
