import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { UsersService } from "../services/users.service";
import { CoursesService } from "../services/courses.service";
import { StoreService } from "../services/storage.service";
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  env = environment.globals;
  featuredPlans: Observable<any[]>;
  currentRate: number = 4
  user: any = {}
  applicationFormURL:string ="" 
  program2009:string ="" 
  program2011:string ="" 
  program2022:string ="" 
  video:string ="" 
  constructor(
    private usrServ: UsersService,
    private coServ: CoursesService,
    private storeServ: StoreService,
  ) { }

  ngOnInit() {
    this.featuredPlans = this.coServ.getBy("featured",true)
    
    this.user = JSON.parse(this.usrServ.getSession('user'));

    this.storeServ.getDownloadURL("public/ApplicationFormMichaelUniversity.pdf").subscribe(url=>{
      this.applicationFormURL = url;
    });

    this.storeServ.getDownloadURL("public/2009 Graduation Program-Michael University.pdf").subscribe(url=>{
      this.program2009 = url;
    });

    this.storeServ.getDownloadURL("public/DOC-20221128-WA0048..pdf").subscribe(url=>{
      this.program2011 = url;
    });

    this.storeServ.getDownloadURL("public/2022 Graduation Program-13.pdf").subscribe(url=>{
      this.program2022 = url;
    });

    this.storeServ.getDownloadURL("public/muLeadership.mp4").subscribe(url=>{
      this.video = url;      
    });
    
  }


  details(id) {
    //this.usrServ.setSession("plan-detail", id)
    this.usrServ.goToWithParam("/course", id)
  }

  scroll(id) {
    let el = document.getElementById(id);
    el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }
  
}
